<template>
    <Shots />
  </template>
  
  <script>
  import Shots from "../components/shots";
  
  export default {
      components: {
        Shots
      }
  }
  </script>
  
  <style>
  
  </style>